/**
 * Sinkhole API Client
 * 
 * @class Sinkhole
 * @classdesc A client for interacting with the Sinkhole API.
 */
class Sinkhole {
    /**
     * Creates an instance of Sinkhole.
     * 
     * @param {string} api_key - The API key for authentication.
     * @param {string|null} realm - The realm for Sinkhole.
     * @param {string} [api_prefix='https://sinkhole.corp.negativeepsilon.com/analytics/api'] - The API prefix for Sinkhole.
     * @param {number} [flush_at=10] - The number of events to flush at.
     * @param {number} [flush_interval=1000] - The interval in milliseconds to flush events.
     */
    constructor({ api_key, realm = null, api_prefix = 'https://sinkhole.corp.negativeepsilon.com/analytics/api', flush_at = 10, flush_interval = 1000 }) {
        this.api_key = api_key;
        this.realm = realm;
        this.api_prefix = api_prefix;
        this.flush_at = flush_at;
        this.flush_interval = flush_interval;
        this.version = '0.1.6';

        this._queue = [];
        this._timer = null;
    }


    /**
     * Tracks an event with the given name, properties, and timestamp.
     * @param {string} name - The name of the event.
     * @param {Object} [properties={}] - The properties associated with the event.
     * @param {Date} [timestamp=null] - The timestamp of the event. If not provided, the current timestamp will be used.
     */
    track({ name, properties = {}, timestamp = null, user_id = undefined, event_id = undefined }) {
        if (timestamp === null) {
            timestamp = new Date().toISOString();
        } else if (timestamp instanceof Date) {
            timestamp = timestamp.toISOString();
        }

        this._queue.push({ name, properties, timestamp, user_id, event_id });

        if (this._queue.length >= this.flush_at) {
            this.flush();
        } else if (!this._timer) {
            this._timer = setTimeout(() => {
                this.flush();
                this._timer = null;
            }, this.flush_interval);
        }
    }


    /**
     * Flushes the queue by sending a batch of items.
     */
    flush() {
        if (this._queue.length > 0) {
            const batch = this._queue.splice(0, this.flush_at);
            this._send(batch);
        }
    }

    async _send(batch) {
        const url = `${this.api_prefix}/v1/bulk`;
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${this.api_key}`,
            'User-Agent': `sinkhole-js/${this.version}`,
        }

        if (this.realm) {
            headers['Sinkhole-Realm'] = this.realm;
        }

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(batch)
            });
            if (response.status != 201) {
                console.error('Error response from the Sinkhole API', response.status, await response.json());
            }
        } catch (error) {
            console.error('Failed to send data to Sinkhole', error);
        }
    }
}

module.exports = Sinkhole;